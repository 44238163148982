import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const { SET_BOARDS, SET_BOARDS_TOTAL, INIT_BOARD, SET_BOARD } = actions.BoardAction

// default state value
const initialState = {
  _boards: [],
  _boardsTotal: 0,
  _board: {},
}

// handle action
export default handleActions(
  {
    [SET_BOARDS]: (state, action) => ({
      ...state,
      _boards: action.payload,
    }),
    [SET_BOARDS_TOTAL]: (state, action) => ({
      ...state,
      _boardsTotal: action.payload,
    }),
    [INIT_BOARD]: (state, action) => ({
      ...state,
      _board: {},
    }),
    [SET_BOARD]: (state, action) => ({
      ...state,
      _board: action.payload,
    }),
  },
  initialState
)
