import api from "../utils/api"

//지점 아이디 중복검사
const getPointCheckId = async (data) => {
  let str = `id=${data.id}`
  if (data.branchSeq !== undefined) {
    str += `&branchSeq=${data.branchSeq}`
  }
  const result = await api.get(`/point/check/id?${str}`)
  return result.data
}
//지점 리스트
const getPoints = async (data) => {
  let query = ``
  if (data.page !== undefined) {
    query += `page=${data.page}`
  }
  if (data.count) {
    query += `&count=${data.count}`
  }
  if (data.custSeq !== undefined) {
    query += `&custSeq=${data.custSeq}`
  }
  if (data.searchType) {
    query += `&searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }
  const result = await api.get(`/points?${query}`)
  return result.data
}
//지점 상세
const getPoint = async (data) => {
  const result = await api.get(`/point?pointSeq=${data.pointSeq}`)
  return result.data
}
//지점 등록
const postPoint = async (data) => {
  const result = await api.post(`/point`, {
    body: data,
  })

  return result.data
}

//지점 수정
const patchPoint = async (data) => {
  const result = await api.patch(`/point`, {
    body: data,
  })
  return result.data
}

//지점 삭제
const deletePoint = async (data) => {
  const result = await api.delete(`/point`, {
    body: data,
  })
  return result.data
}

//지점 엑셀저장
const getDownloadExcel = async (data) => {
  let query = `?`
  if (data.custSeq !== undefined) {
    query += `custSeq=${data.custSeq}`
  }
  if (data.searchType) {
    query += `&searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }
  const result = await api.get(`/points/download${query}`, { options: { responseType: "blob" } })
  return result.data
}

//지점 엑셀 등록
const postPointsUpload = async (data) => {
  const result = await api.post(`/points/upload`, {
    body: data,
  })
  return result.data
}

export default {
  getPointCheckId,
  getPoints,
  getPoint,
  postPoint,
  patchPoint,
  deletePoint,
  getDownloadExcel,
  postPointsUpload,
}
