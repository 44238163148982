import api from "../utils/api"

//거래처 리스트
const getCustomers = async (data) => {
  let query = ``
  if (data.page !== undefined) {
    query += `page=${data.page}`
  }
  if (data.count) {
    query += `&count=${data.count}`
  }
  if (data.searchType) {
    query += `&searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }
  const result = await api.get(`/customers?${query}`)
  return result.data
}
//거래처 상세
const getCustomer = async (data) => {
  const result = await api.get(`/customer?custSeq=${data.custSeq}`)
  return result.data
}
//거래처 등록
const postCustomer = async (data) => {
  const result = await api.post(`/customer`, {
    body: data,
  })
  return result.data
}
//거래처 수정
const patchCustomer = async (data) => {
  const result = await api.patch(`/customer`, {
    body: data,
  })
  return result.data
}
//거래처 삭제
const deleteCustomer = async (data) => {
  const result = await api.delete(`/customer`, {
    body: data,
  })
  return result.data
}
//거래처 엑셀저장
const getDownloadExcel = async (data) => {
  let query = `?`
  if (data.searchType) {
    query += `searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }
  const result = await api.get(`/customers/download${query}`, { options: { responseType: "blob" } })
  return result.data
}
export default {
  getCustomers,
  getCustomer,
  postCustomer,
  patchCustomer,
  deleteCustomer,
  getDownloadExcel,
}
