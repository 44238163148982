import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const {
  SET_DRIVERS,
  SET_DRIVERS_TOTAL,
  SET_DRIVERS_LOCAL,
  SET_DRIVERS_LOCAL_TOTAL,
  SET_DRIVER,
  INIT_DRIVER,
  SET_DRIVER_LOCAL,
  INIT_DRIVER_LOCAL,
  SET_DRIVER_POUCHS,
  INIT_DRIVER_POUCHS,
} = actions.DriverAction
// default state value
const initialState = {
  _drivers: [],
  _driversLocal: [],
  _driverLocal: {},
  _driver: {},

  _driversTotal: 0,
  _driversLocalTotal: 0,
  _driverPouchs: [],
}

// handle action
export default handleActions(
  {
    [INIT_DRIVER_POUCHS]: (state, action) => ({
      ...state,
      _driverPouchs: [],
    }),
    [SET_DRIVER_POUCHS]: (state, action) => ({
      ...state,
      _driverPouchs: action.payload,
    }),
    [SET_DRIVERS]: (state, action) => ({
      ...state,
      _drivers: action.payload,
    }),
    [SET_DRIVERS_TOTAL]: (state, action) => ({
      ...state,
      _driversTotal: action.payload,
    }),
    [SET_DRIVERS_LOCAL]: (state, action) => ({
      ...state,
      _driversLocal: action.payload,
    }),
    [SET_DRIVERS_LOCAL_TOTAL]: (state, action) => ({
      ...state,
      _driversLocalTotal: action.payload,
    }),

    [SET_DRIVER]: (state, action) => ({
      ...state,
      _driver: action.payload,
    }),

    [INIT_DRIVER]: (state, action) => ({
      ...state,
      _driver: {},
    }),
    [SET_DRIVER_LOCAL]: (state, action) => ({
      ...state,
      _driverLocal: action.payload,
    }),
    [INIT_DRIVER_LOCAL]: (state, action) => ({
      ...state,
      _driverLocal: {},
    }),
  },
  initialState
)
