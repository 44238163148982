import api from "../utils/api"

//지사 리스트
const getBranchs = async (data) => {
  let query = ``

  if (data.page !== undefined) {
    query += `page=${data.page}`
  }
  if (data.count) {
    query += `&count=${data.count}`
  }
  if (data.searchType) {
    query += `&searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }
  const result = await api.get(`/branchs?${query}`)
  return result.data
}
//지사 상세
const getBranch = async (data) => {
  const result = await api.get(`/branch?branchSeq=${data.branchSeq}`)
  return result.data
}

//지사 등록
const postBranch = async (data) => {
  const result = await api.post(`/branch`, {
    body: data,
  })
  return result.data
}

//지사 수정
const patchBranch = async (data) => {
  const result = await api.patch(`/branch`, {
    body: data,
  })
  return result.data
}

//지사 삭제
const deleteBranch = async (data) => {
  const result = await api.delete(`/branch`, {
    body: data,
  })
  return result.data
}

//지사 아이디 중복검사
const getBranchCheckId = async (data) => {
  let str = `id=${data.id}`
  if (data.branchSeq) {
    str += `&branchSeq=${data.branchSeq}`
  }
  const result = await api.get(`/branch/check/id?${str}`)
  return result.data
}

//지사 엑셀저장
const getDownloadExcel = async (data) => {
  let query = `?`
  if (data.searchType) {
    query += `searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }
  const result = await api.get(`/branchs/download${query}`, { options: { responseType: "blob" } })
  return result.data
}

export default {
  getBranchs,
  getBranch,
  postBranch,
  patchBranch,
  deleteBranch,
  getBranchCheckId,
  getDownloadExcel,
}
