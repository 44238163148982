import api from "../utils/api"

//사원 리스트
const getEmployees = async (data) => {
  let quary = `?`
  if (data.page !== undefined) {
    quary += `page=${data.page}`
  }
  if (data.count) {
    quary += `&count=${data.count}`
  }
  if (data.searchType) {
    quary += `&searchType=${data.searchType}`
  }
  if (data.search) {
    quary += `&search=${data.search}`
  }
  const result = await api.get(`/employees${quary}`)
  return result.data
}

//사원 상세
const getEmployee = async (data) => {
  const result = await api.get(`/employee?empSeq=${data.empSeq}`)
  return result.data
}

//사원 등록
const postEmployee = async (data) => {
  const result = await api.post(`/employee`, { body: data })
  return result.data
}

//사원 수정
const patchEmployee = async (data) => {
  const result = await api.patch(`/employee`, { body: data })
  return result.data
}

//사원 삭제
const deleteEmployee = async (data) => {
  const result = await api.delete(`/employee`, { body: data })
  return result.data
}

//사원 아이디 중복검사
const getEmployeeCheckId = async (data) => {
  let str = `id=${data.id}`
  if (str.empSeq !== undefined) {
    str += `empSeq=${data.empSeq}`
  }
  const result = await api.get(`/employee/check/id?${str}`)
  return result.data
}

//사원 엑셀저장
const getDownloadExcel = async (data) => {
  let query = `?`
  if (data.searchType) {
    query += `searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }
  const result = await api.get(`/employees/download${query}`, { options: { responseType: "blob" } })
  return result.data
}
export default {
  getEmployees,
  getEmployee,
  postEmployee,
  patchEmployee,
  deleteEmployee,
  getEmployeeCheckId,
  getDownloadExcel,
}
