import signApis from "../apis/signApis"
import cookie from "react-cookies"

//## NoAuth
//로그인
export const postSignin = (param) => async (dispatch) => {
  const result = await signApis.postSignin(param)
  if (result.code === 0) {
    cookie.save("adminName", result.data.admin.empName, { path: "/", maxAge: 3600 * 24 })
    return true
  } else {
    return false
  }
}
//권한체크
export const checkAuth = () => async (dispatch) => {
  const result = await signApis.checkAuth()
  if (result.code === 0) {
    return true
  } else {
    return false
  }
}

//로그아웃
export const logout = () => async () => {
  const result = await signApis.logout()
  if (result.code === 0) {
    return true
  } else {
    return false
  }
}
