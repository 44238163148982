import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const { SET_POINTS, SET_POINT, SET_POINTS_TOTAL, INIT_POINT } = actions.PointAction

// default state value
const initialState = {
  _points: [],
  _point: {},
  _pointsTotal: 0,
}

// handle action
export default handleActions(
  {
    [SET_POINTS]: (state, action) => ({
      ...state,
      _points: action.payload,
    }),

    [INIT_POINT]: (state, action) => ({
      ...state,
      _point: {},
    }),
    [SET_POINTS_TOTAL]: (state, action) => ({
      ...state,
      _pointsTotal: action.payload,
    }),
    [SET_POINT]: (state, action) => ({
      ...state,
      _point: action.payload,
    }),
  },
  initialState
)
