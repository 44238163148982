import { createAction } from "redux-actions"

// Action Type
export const SET_LOADING = "@volunteer/config/SET_LOADING"

export const setLoading = createAction(SET_LOADING)

export const SET_DOCU_PAGE = "@volunteer/config/SET_DOCU_PAGE"

export const setDocuPage = createAction(SET_DOCU_PAGE)
