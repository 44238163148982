import { createAction } from "redux-actions"
import manageApis from "../apis/manageApis"

// action type
export const SET_MANAGE_FREIGHT_TRACKS = "@tramslogitics/admin/SET_MANAGE_FREIGHT_TRACKS"
export const SET_MANAGE_FREIGHT_TRACKS_TOTAL = "@tramslogitics/admin/SET_MANAGE_FREIGHT_TRACKS_TOTAL"
export const SET_MANAGE_POUCH_TRACKS = "@tramslogitics/admin/SET_MANAGE_POUCH_TRACKS"
export const SET_MANAGE_POUCH_TRACKS_TOTAL = "@tramslogitics/admin/SET_MANAGE_POUCH_TRACKS_TOTAL"
export const SET_MANAGE_POUCH_TRACKS_SETTLE_PRICE = "@tramslogitics/admin/SET_MANAGE_POUCH_TRACKS_SETTLE_PRICE"
export const SET_MANAGE_POUCH_TRACKS_BILL_PRICE = "@tramslogitics/admin/SET_MANAGE_POUCH_TRACKS_BILL_PRICE"

export const SET_MANAGE_FREIGHT_TRACKS_SETTLE_PRICE = "@tramslogitics/admin/SET_MANAGE_FREIGHT_TRACKS_SETTLE_PRICE"
export const SET_MANAGE_FREIGHT_TRACKS_BILL_PRICE = "@tramslogitics/admin/SET_MANAGE_FREIGHT_TRACKS_BILL_PRICE"

// action function
export const setManageFreightTracks = createAction(SET_MANAGE_FREIGHT_TRACKS)
export const setManageFreightTracksTotal = createAction(SET_MANAGE_FREIGHT_TRACKS_TOTAL)
export const setManagePouchTracks = createAction(SET_MANAGE_POUCH_TRACKS)
export const setManagePouchTracksTotal = createAction(SET_MANAGE_POUCH_TRACKS_TOTAL)

export const setManagePouchTracksSettlePrice = createAction(SET_MANAGE_POUCH_TRACKS_SETTLE_PRICE)
export const setManagePouchTracksBillPrice = createAction(SET_MANAGE_POUCH_TRACKS_BILL_PRICE)

export const setManageFreightTracksSettlePrice = createAction(SET_MANAGE_FREIGHT_TRACKS_SETTLE_PRICE)
export const setManageFreightTracksBillPrice = createAction(SET_MANAGE_FREIGHT_TRACKS_BILL_PRICE)

//청구/정산 소화물 리스트
export const getManageFreightTracks = (param) => async (dispatch) => {
  const result = await manageApis.getManageFreightTracks(param)
  if (result.code === 0) {
    dispatch(setManageFreightTracks(result.data.tracks))
    dispatch(setManageFreightTracksTotal(result.data.total))
    dispatch(setManageFreightTracksSettlePrice(result.data.price.settlePrice))
    dispatch(setManageFreightTracksBillPrice(result.data.price.billPrice))
    return true
  } else return false
}
//청구/정산 행낭 리스트
export const getManagePouchTracks = (param) => async (dispatch) => {
  const result = await manageApis.getManagePouchTracks(param)
  if (result.code === 0) {
    dispatch(setManagePouchTracks(result.data.tracks))
    dispatch(setManagePouchTracksTotal(result.data.total))
    dispatch(setManagePouchTracksSettlePrice(result.data.price.settlePrice))
    dispatch(setManagePouchTracksBillPrice(result.data.price.billPrice))
    return true
  } else return false
}
//청구/정산 소화물 금액 수정
export const patchManageFreightPrice = (param) => async () => {
  const result = await manageApis.patchManageFreightPrice(param)
  if (result.code === 0) {
    return true
  } else return false
}
//청구/정산 행낭 금액 수정
export const patchManagePouchPrice = (param) => async () => {
  const result = await manageApis.patchManagePouchPrice(param)
  if (result.code === 0) {
    return true
  } else return false
}

//청구/정산 소화물, 엑셀저장
export const getFreightDownloadExcel = (param) => async () => {
  const result = await manageApis.getFreightDownloadExcel(param)
  return result
}

//청구/정산 행낭, 엑셀저장
export const getPouchDownloadExcel = (param) => async () => {
  const result = await manageApis.getPouchDownloadExcel(param)
  return result
}
