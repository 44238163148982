import { createAction } from "redux-actions"
import pouchApis from "../apis/pouchApis"

// action type
export const SET_POUCH_TRACKS = "@tramslogitics/admin/SET_POUCH_TRACKS"
export const SET_POUCH_TRACKS_TOTAL = "@tramslogitics/admin/SET_POUCH_TRACKS_TOTAL"
export const SET_POUCHS = "@tramslogitics/admin/SET_POUCHS"
export const SET_POUCHS_TOTAL = "@tramslogitics/admin/SET_POUCHS_TOTAL"
export const SET_POUCH = "@tramslogitics/admin/SET_POUCH"
export const INIT_POUCH = "@tramslogitics/admin/INIT_POUCH"
export const SET_POUCH_PRINT_TOP = "@tramslogitics/admin/SET_POUCH_PRINT_TOP"
export const SET_POUCH_PRINT_BOTTOM = "@tramslogitics/admin/SET_POUCH_PRINT_BOTTOM"

export const INIT_POUCH_PRINT_TOP = "@tramslogitics/admin/INIT_POUCH_PRINT_TOP"
export const INIT_POUCH_PRINT_BOTTOM = "@tramslogitics/admin/INIT_POUCH_PRINT_BOTTOM"

// action function
export const setPouchTracks = createAction(SET_POUCH_TRACKS)
export const setPouchTracksTotal = createAction(SET_POUCH_TRACKS_TOTAL)
export const setPouchs = createAction(SET_POUCHS)
export const setPouchsTotal = createAction(SET_POUCHS_TOTAL)
export const setPouch = createAction(SET_POUCH)
export const initPouch = createAction(INIT_POUCH)
export const setPouchPrintTop = createAction(SET_POUCH_PRINT_TOP)
export const setPouchPrintBottom = createAction(SET_POUCH_PRINT_BOTTOM)
export const initPouchPrintTop = createAction(INIT_POUCH_PRINT_TOP)
export const initPouchPrintBottom = createAction(INIT_POUCH_PRINT_BOTTOM)

//행낭 추적 리스트
export const getPouchTracks = (param) => async (dispatch) => {
  const result = await pouchApis.getPouchTracks(param)
  if (result.code === 0) {
    dispatch(setPouchTracks(result.data.tracks))
    dispatch(setPouchTracksTotal(result.data.total))
    return true
  } else return false
}
//행낭 리스트
export const getPouchs = (param) => async (dispatch) => {
  const result = await pouchApis.getPouchs(param)
  if (result.code === 0) {
    dispatch(setPouchs(result.data.pouchs))
    dispatch(setPouchsTotal(result.data.total))
    return true
  } else return false
}
//행낭 상세
export const getPouch = (param) => async (dispatch) => {
  const result = await pouchApis.getPouch(param)
  if (result.code === 0) {
    dispatch(setPouch(result.data.pouch))
    return true
  } else return false
}
//행낭 등록
export const postPouch = (param) => async (dispatch) => {
  const result = await pouchApis.postPouch(param)
  if (result.code === 0) {
    return true
  } else return false
}
//행낭 수정
export const patchPouch = (param) => async (dispatch) => {
  const result = await pouchApis.patchPouch(param)
  if (result.code === 0) {
    return true
  } else return false
}
//행낭 삭제
export const deletePouch = (param) => async (dispatch) => {
  const result = await pouchApis.deletePouch(param)
  if (result.code === 0) {
    return true
  } else return false
}

//행낭추적 엑셀저장
export const getTrackDownloadExcel = (param) => async () => {
  const result = await pouchApis.getTrackDownloadExcel(param)
  return result
}

//행낭관리 엑셀저장
export const getManageDownloadExcel = (param) => async () => {
  const result = await pouchApis.getManageDownloadExcel(param)
  return result
}
