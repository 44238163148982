import api from "../utils/api"

//대낭 추적 리스트
const getLargePouchTracks = async (data) => {
  let query = ``
  if (data.page !== undefined) {
    query += `page=${data.page}`
  }
  if (data.count) {
    query += `&count=${data.count}`
  }
  if (data.startDate) {
    query += `&startDate=${data.startDate}`
  }
  if (data.endDate) {
    query += `&endDate=${data.endDate}`
  }
  if (data.searchType) {
    query += `&searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }
  const result = await api.get(`/large/pouch/tracks?${query}`)
  return result.data
}
//대낭 리스트
const getLargePouchs = async (data) => {
  let query = ``
  if (data.page !== undefined) {
    query += `page=${data.page}`
  }
  if (data.count) {
    query += `&count=${data.count}`
  }
  if (data.startDate) {
    query += `&startDate=${data.startDate}`
  }
  if (data.endDate) {
    query += `&endDate=${data.endDate}`
  }
  if (data.searchType) {
    query += `&searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }
  const result = await api.get(`/large/pouchs?${query}`)
  return result.data
}
//대낭 리스트
const getLargePouchItems = async (data) => {
  let query = `?`
  if (data.page !== undefined) {
    query += `page=${data.page}`
  }
  if (data.count) {
    query += `&count=${data.count}`
  }
  if (data.largeTrackSeq) {
    query += `&largeTrackSeq=${data.largeTrackSeq}`
  }
  if (data.searchType) {
    query += `&searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }
  const result = await api.get(`/large/pouch/items${query}`)
  return result.data
}
//대상 상세
const getLargePouch = async (data) => {
  const result = await api.get(`/large/pouch?largePouchSeq=${data.largePouchSeq}`)
  return result.data
}
//대낭 등록
const postLargePouch = async (data) => {
  const result = await api.post(`/large/pouch`, { body: data })
  return result.data
}
//대낭 수정
const patchLargePouch = async (data) => {
  const result = await api.patch(`/large/pouch`, { body: data })
  return result.data
}
//대낭삭제
const deleteLargePouch = async (data) => {
  const result = await api.delete(`/large/pouch`, { body: data })
  return result.data
}

//대낭추적 엑셀저장
const getTrackDownloadExcel = async (data) => {
  let query = `?`
  if (data.startDate) {
    query += `startDate=${data.startDate}`
  }
  if (data.endDate) {
    query += `&endDate=${data.endDate}`
  }
  if (data.searchType) {
    query += `&searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }

  const result = await api.get(`/large/pouch/tracks/download${query}`, { options: { responseType: "blob" } })
  return result.data
}

//대낭관리 엑셀저장
const getManageDownloadExcel = async (data) => {
  let query = `?`

  if (data.searchType) {
    query += `searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }

  const result = await api.get(`/large/pouchs/download${query}`, { options: { responseType: "blob" } })
  return result.data
}

//대낭추적상세 엑셀저장
const getTrackDetailDownloadExcel = async (data) => {
  let query = `?`

  if (data.searchType) {
    query += `searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }
  if (data.largeTrackSeq) {
    query += `&largeTrackSeq=${data.largeTrackSeq}`
  }
  const result = await api.get(`/large/pouch/items/download${query}`, { options: { responseType: "blob" } })
  return result.data
}
export default {
  getLargePouchTracks,
  getLargePouchs,
  getLargePouchItems,
  getLargePouch,
  postLargePouch,
  patchLargePouch,
  deleteLargePouch,
  getTrackDownloadExcel,
  getManageDownloadExcel,
  getTrackDetailDownloadExcel,
}
