import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const { SET_DOCUMENTS, SET_DOCUMENTS_TOTAL, INIT_DOCUMENT, SET_DOCUMENT, SET_DOCUMENT_PRINT, INIT_DOCUMENT_PRINT } = actions.DocumentAction

// default state value
const initialState = {
  _documents: [],
  _documentsTotal: 0,
  _document: {},
  _documentPrint: [],
}

// handle action
export default handleActions(
  {
    [SET_DOCUMENT_PRINT]: (state, action) => ({
      ...state,
      _documentPrint: action.payload,
    }),
    [INIT_DOCUMENT_PRINT]: (state, action) => ({
      ...state,
      _documentPrint: [],
    }),
    [SET_DOCUMENTS]: (state, action) => ({
      ...state,
      _documents: action.payload,
    }),
    [SET_DOCUMENTS_TOTAL]: (state, action) => ({
      ...state,
      _documentsTotal: action.payload,
    }),
    [INIT_DOCUMENT]: (state, action) => ({
      ...state,
      _document: {},
    }),
    [SET_DOCUMENT]: (state, action) => ({
      ...state,
      _document: action.payload,
    }),
  },
  initialState
)
