import * as ConfigAction from "./configAction"
import * as SignAction from "./signAction"
import * as BranchAction from "./branchAction"
import * as EmployeeAction from "./employeeAction"
import * as DriverAction from "./driverAction"
import * as CustomerAction from "./customerAction"
import * as PointAction from "./pointAction"
import * as BoardAction from "./boardAction"
import * as PouchAction from "./pouchAction"
import * as LargePouchAction from "./largePouchAction"
import * as FreightAction from "./freightAction"
import * as DocumentAction from "./documentAction"
import * as ManageAction from "./manageAction"

export default {
  ConfigAction,
  SignAction,
  BranchAction,
  EmployeeAction,
  DriverAction,
  CustomerAction,
  PointAction,
  BoardAction,
  PouchAction,
  LargePouchAction,
  FreightAction,
  DocumentAction,
  ManageAction,
}
