import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const {
  SET_POUCH_TRACKS,
  SET_POUCHS,
  SET_POUCH,
  INIT_POUCH,
  SET_POUCH_TRACKS_TOTAL,
  SET_POUCHS_TOTAL,
  SET_POUCH_PRINT_TOP,
  SET_POUCH_PRINT_BOTTOM,
  INIT_POUCH_PRINT_TOP,
  INIT_POUCH_PRINT_BOTTOM,
} = actions.PouchAction
// default state value
const initialState = {
  _pouchTracks: [],
  _pouchs: [],
  _pouch: [],
  _pouchTracksTotal: 0,
  _pouchsTotal: 0,
  _pouchPrintTop: [],
  _pouchPrintBottom: [],
}

// handle action
export default handleActions(
  {
    [INIT_POUCH_PRINT_TOP]: (state, action) => ({
      ...state,
      _pouchPrintTop: [],
    }),
    [INIT_POUCH_PRINT_BOTTOM]: (state, action) => ({
      ...state,
      _pouchPrintBottom: [],
    }),
    [SET_POUCH_PRINT_TOP]: (state, action) => ({
      ...state,
      _pouchPrintTop: action.payload,
    }),
    [SET_POUCH_PRINT_BOTTOM]: (state, action) => ({
      ...state,
      _pouchPrintBottom: action.payload,
    }),

    [SET_POUCHS_TOTAL]: (state, action) => ({
      ...state,
      _pouchsTotal: action.payload,
    }),
    [SET_POUCH_TRACKS]: (state, action) => ({
      ...state,
      _pouchTracks: action.payload,
    }),
    [SET_POUCH_TRACKS_TOTAL]: (state, action) => ({
      ...state,
      _pouchTracksTotal: action.payload,
    }),
    [SET_POUCHS]: (state, action) => ({
      ...state,
      _pouchs: action.payload,
    }),
    [SET_POUCH]: (state, action) => ({
      ...state,
      _pouch: action.payload,
    }),
    [INIT_POUCH]: (state, action) => ({
      ...state,
      _pouch: [],
    }),
  },
  initialState
)
