import api from "../utils/api"

//기사 리스트
const getDrivers = async (data) => {
  let quary = "?"
  if (data.page !== undefined) {
    quary += `page=${data.page}`
  }
  if (data.count) {
    quary += `&count=${data.count}`
  }
  if (data.type) {
    quary += `&type=${data.type}`
  }
  if (data.isOkcheon) {
    quary += `&isOkcheon=${data.isOkcheon}`
  }
  if (data.searchType) {
    quary += `&searchType=${data.searchType}`
  }
  if (data.search) {
    quary += `&search=${data.search}`
  }
  console.log("apis", quary)
  const result = await api.get(`/drivers${quary}`)
  return result.data
}
//기사 상세
const getDriver = async (data) => {
  const result = await api.get(`/driver?driverSeq=${data.driverSeq}`)
  return result.data
}

//기사
const postDriver = async (data) => {
  const result = await api.post(`/driver`, {
    body: data,
  })
  return result.data
}

//기사 수정
const patchDriver = async (data) => {
  const result = await api.patch(`/driver`, {
    body: data,
  })
  return result.data
}

//기사 삭제
const deleteDriver = async (data) => {
  const result = await api.delete(`/driver`, {
    body: data,
  })
  return result.data
}

//기사 아이디 중복검사
const getDriverCheckId = async (data) => {
  let str = `id=${data.id}`
  if (data.driverSeq !== undefined) {
    str += `&driverSeq=${data.driverSeq}`
  }
  const result = await api.get(`/driver/check/id?${str}`, {
    body: data,
  })
  return result.data
}

//기사 엑셀저장
const getDownloadExcel = async (data) => {
  let quary = `?`
  if (data.type) {
    quary += `type=${data.type}`
  }
  if (data.isOkcheon !== undefined) {
    quary += `&isOkcheon=${data.isOkcheon}`
  }
  if (data.searchType) {
    quary += `&searchType=${data.searchType}`
  }
  if (data.search) {
    quary += `&search=${data.search}`
  }
  const result = await api.get(`/drivers/download${quary}`, { options: { responseType: "blob" } })
  return result.data
}

//기사 행낭리스트
const getDriverPouchs = async (data) => {
  console.log("!!!!", data)
  const result = await api.get(`/driver/pouchs?driverSeq=${data.driverSeq}`)
  return result.data
}
export default {
  getDrivers,
  getDriver,
  postDriver,
  patchDriver,
  deleteDriver,
  getDriverCheckId,
  getDownloadExcel,
  getDriverPouchs,
}
