import { createAction } from "redux-actions"
import employeeApis from "../apis/employeeApis"

// action type
export const SET_EMPLOYEES = "@tramslogitics/admin/SET_BRANCHS"
export const SET_EMPLOYEE = "@tramslogitics/admin/SET_BRANCH"
export const INIT_EMPLOYEE = "@tramslogitics/admin/INIT_EMPLOYEE"
export const SET_EMPLOYEES_TOTAL = "@tramslogitics/admin/SET_EMPLOYEES_TOTAL"
// action function
export const setEmployees = createAction(SET_EMPLOYEES)
export const setEmployee = createAction(SET_EMPLOYEE)
export const initEmployee = createAction(INIT_EMPLOYEE)
export const setEmployeesTotal = createAction(SET_EMPLOYEES_TOTAL)

// action api function
export const getEmployees = (param) => async (dispatch) => {
  const result = await employeeApis.getEmployees(param)
  if (result.code === 0) {
    dispatch(setEmployees(result.data.employees))
    dispatch(setEmployeesTotal(result.data.total))
    return true
  } else return false
}

export const getEmployee = (param) => async (dispatch) => {
  const result = await employeeApis.getEmployee(param)
  if (result.code === 0) {
    dispatch(setEmployee(result.data.employee))
    return true
  } else return false
}

export const postEmployee = (param) => async (dispatch) => {
  const result = await employeeApis.postEmployee(param)
  if (result.code === 0) {
    return true
  } else return false
}

export const patchEmployee = (param) => async (dispatch) => {
  const result = await employeeApis.patchEmployee(param)
  if (result.code === 0) {
    return true
  } else return false
}

export const deleteEmployee = (param) => async (dispatch) => {
  const result = await employeeApis.deleteEmployee(param)
  if (result.code === 0) {
    return true
  } else return false
}

export const getEmployeeCheckId = (param) => async (dispatch) => {
  const result = await employeeApis.getEmployeeCheckId(param)
  if (result.code === 0) {
    return result.data.status
  } else return false
}

//사원 엑셀저장
export const getDownloadExcel = (param) => async () => {
  const result = await employeeApis.getDownloadExcel(param)
  return result
}
