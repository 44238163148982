import { createAction } from "redux-actions"
import largePouchApis from "../apis/largePouchApis"

// action type
export const SET_LARGE_POUCH_TRACKS = "@tramslogitics/admin/SET_LARGE_POUCH_TRACKS"
export const SET_LARGE_POUCH_TRACKS_TOTAL = "@tramslogitics/admin/SET_LARGE_POUCH_TRACKS_TOTAL"
export const SET_LARGE_POUCHS = "@tramslogitics/admin/SET_LARGE_POUCHS"
export const SET_LARGE_POUCHS_TOTAL = "@tramslogitics/admin/SET_LARGE_POUCHS_TOTAL"
export const SET_LARGE_POUCH_ITEMS = "@tramslogitics/admin/SET_LARGE_POUCH_ITEMS"
export const SET_LARGE_POUCH_ITEMS_TOTAL = "@tramslogitics/admin/SET_LARGE_POUCH_ITEMS_TOTAL"
export const INIT_LARGE_POUCH = "@tramslogitics/admin/INIT_LARGE_POUCH"
export const SET_LARGE_POUCH = "@tramslogitics/admin/SET_LARGE_POUCH"

export const SET_LARGE_POUCH_PRINT = "@tramslogitics/admin/SET_LARGE_POUCH_PRINT"

// action function
export const setLargePouchTracks = createAction(SET_LARGE_POUCH_TRACKS)
export const setLargePouchTracksTotal = createAction(SET_LARGE_POUCH_TRACKS_TOTAL)
export const setLargePouchs = createAction(SET_LARGE_POUCHS)
export const setLargePouchsTotal = createAction(SET_LARGE_POUCHS_TOTAL)
export const setLargePouchItems = createAction(SET_LARGE_POUCH_ITEMS)
export const setLargePouchItemsTotal = createAction(SET_LARGE_POUCH_ITEMS_TOTAL)
export const initLargePouch = createAction(INIT_LARGE_POUCH)
export const setLargePouch = createAction(SET_LARGE_POUCH)

export const setLargePouchPrint = createAction(SET_LARGE_POUCH_PRINT)

//대낭 추적 리스트
export const getLargePouchTracks = (param) => async (dispatch) => {
  const result = await largePouchApis.getLargePouchTracks(param)
  if (result.code === 0) {
    dispatch(setLargePouchTracks(result.data.tracks))
    dispatch(setLargePouchTracksTotal(result.data.total))
    return true
  } else return false
}
//대낭 리스트
export const getLargePouchs = (param) => async (dispatch) => {
  const result = await largePouchApis.getLargePouchs(param)
  if (result.code === 0) {
    dispatch(setLargePouchs(result.data.largePouchs))
    dispatch(setLargePouchsTotal(result.data.total))
    return true
  } else return false
}
//대낭 리스트
export const getLargePouchItems = (param) => async (dispatch) => {
  const result = await largePouchApis.getLargePouchItems(param)
  if (result.code === 0) {
    dispatch(setLargePouchItems(result.data.items))
    dispatch(setLargePouchItemsTotal(result.data.total))
    return true
  } else return false
}
//대상 상세
export const getLargePouch = (param) => async (dispatch) => {
  const result = await largePouchApis.getLargePouch(param)
  if (result.code === 0) {
    dispatch(setLargePouch(result.data.largePouch))
    return true
  } else return false
}
//대낭 등록
export const postLargePouch = (param) => async (dispatch) => {
  const result = await largePouchApis.postLargePouch(param)
  if (result.code === 0) {
    return true
  } else return false
}
//대낭 수정
export const patchLargePouch = (param) => async (dispatch) => {
  const result = await largePouchApis.patchLargePouch(param)
  if (result.code === 0) {
    return true
  } else return false
}
//대낭삭제
export const deleteLargePouch = (param) => async (dispatch) => {
  const result = await largePouchApis.deleteLargePouch(param)
  if (result.code === 0) {
    return true
  } else return false
}

//대낭추적 엑셀저장
export const getTrackDownloadExcel = (param) => async () => {
  const result = await largePouchApis.getTrackDownloadExcel(param)
  return result
}

//대낭관리 엑셀저장
export const getManageDownloadExcel = (param) => async () => {
  const result = await largePouchApis.getManageDownloadExcel(param)
  return result
}

//대낭추적상세 엑셀저장
export const getTrackDetailDownloadExcel = (param) => async () => {
  const result = await largePouchApis.getTrackDetailDownloadExcel(param)
  return result
}
