import api from "../utils/api"

//로그인
const postSignin = async (data) => {
  const result = await api.post("/signin", {
    body: data,
  })
  return result.data
}
// 권한 체크
const checkAuth = async () => {
  const result = await api.get("/check/auth")
  return result.data
}

//로그아웃
const logout = async () => {
  const result = await api.post("/logout")
  return result
}

export default {
  postSignin,
  checkAuth,
  logout,
}
