import { createAction } from "redux-actions"
import customerApis from "../apis/customerApis"

// action type
export const SET_CUSTOMERS = "@tramslogitics/admin/SET_CUSTOMERS"
export const SET_CUSTOMER = "@tramslogitics/admin/SET_CUSTOMER"
export const SET_CUSTOMERS_TOTAL = "@tramslogitics/admin/SET_CUSTOMERS_TOTAL"
export const INIT_CUSTOMER = "@tramslogitics/admin/INIT_CUSTOMER"
// action function
export const setCustomers = createAction(SET_CUSTOMERS)
export const setCustomer = createAction(SET_CUSTOMER)
export const setCustomersTotal = createAction(SET_CUSTOMERS_TOTAL)
export const initCustomer = createAction(INIT_CUSTOMER)

//거래처 리스트
export const getCustomers = (param) => async (dispatch) => {
  const result = await customerApis.getCustomers(param)

  if (result.code === 0) {
    dispatch(setCustomers(result.data.customers))
    dispatch(setCustomersTotal(result.data.total))
    return true
  } else return false
}

//거래처 상세
export const getCustomer = (param) => async (dispatch) => {
  const result = await customerApis.getCustomer(param)
  if (result.code === 0) {
    dispatch(setCustomer(result.data.customer))
    return true
  } else return false
}

//거래처 등록
export const postCustomer = (param) => async (dispatch) => {
  const result = await customerApis.postCustomer(param)
  if (result.code === 0) {
    return true
  } else return false
}
//거래처 수정
export const patchCustomer = (param) => async (dispatch) => {
  const result = await customerApis.patchCustomer(param)
  if (result.code === 0) {
    return true
  } else return false
}
//거래처 삭제
export const deleteCustomer = (param) => async (dispatch) => {
  const result = await customerApis.deleteCustomer(param)
  if (result.code === 0) {
    return true
  } else return false
}

//거래처 엑셀저장
export const getDownloadExcel = (param) => async () => {
  const result = await customerApis.getDownloadExcel(param)
  return result
}
