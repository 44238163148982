import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const {
  SET_FREIGHT_TRACKS,
  SET_FREIGHT_TRACKS_TOTAL,
  SET_FREIGHTS,
  SET_FREIGHTS_TOTAL,
  INIT_FREIGHT,
  SET_FREIGHT,
  SET_FREIGHT_PRINT,
  INIT_FREIGHT_PRINT,
} = actions.FreightAction

// default state value
const initialState = {
  _freightTracks: [],
  _freightTracksTotal: 0,
  _freights: [],
  _freightsTotal: 0,
  _freight: {},
  _freightPrint: [],
}

// handle action
export default handleActions(
  {
    [SET_FREIGHT_PRINT]: (state, action) => ({
      ...state,
      _freightPrint: action.payload,
    }),
    [INIT_FREIGHT_PRINT]: (state, action) => ({
      ...state,
      _freightPrint: [],
    }),

    [SET_FREIGHT_TRACKS]: (state, action) => ({
      ...state,
      _freightTracks: action.payload,
    }),
    [SET_FREIGHT_TRACKS_TOTAL]: (state, action) => ({
      ...state,
      _freightTracksTotal: action.payload,
    }),
    [SET_FREIGHTS]: (state, action) => ({
      ...state,
      _freights: action.payload,
    }),
    [SET_FREIGHTS_TOTAL]: (state, action) => ({
      ...state,
      _freightsTotal: action.payload,
    }),
    [INIT_FREIGHT]: (state, action) => ({
      ...state,
      _freight: {},
    }),
    [SET_FREIGHT]: (state, action) => ({
      ...state,
      _freight: action.payload,
    }),
  },
  initialState
)
