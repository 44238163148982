import api from "../utils/api"

//소화물 추적 리스트
const getFreightTracks = async (data) => {
  let query = ``
  if (data.page !== undefined) {
    query += `page=${data.page}`
  }
  if (data.count) {
    query += `&count=${data.count}`
  }
  if (data.startDate) {
    query += `&startDate=${data.startDate}`
  }
  if (data.endDate) {
    query += `&endDate=${data.endDate}`
  }
  if (data.searchType) {
    query += `&searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }
  const result = await api.get(`/freight/tracks?${query}`)
  return result.data
}
//소화물 리스트
const getFreights = async (data) => {
  let query = ``
  if (data.page !== undefined) {
    query += `page=${data.page}`
  }
  if (data.count) {
    query += `&count=${data.count}`
  }
  if (data.startDate) {
    query += `&startDate=${data.startDate}`
  }
  if (data.endDate) {
    query += `&endDate=${data.endDate}`
  }
  if (data.isScan !== undefined) {
    query += `&isScan=${data.isScan}`
  }
  if (data.searchType) {
    query += `&searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }
  const result = await api.get(`/freights?${query}`)
  return result.data
}
//소화물 상세
const getFreight = async (data) => {
  const result = await api.get(`/freight?freightSeq=${data.freightSeq}`)
  return result.data
}
//소화물 등록
const postFreight = async (data) => {
  const result = await api.post(`/freight`, { body: data })
  return result.data
}
//소화물 수정
const patchFreight = async (data) => {
  const result = await api.patch(`/freight`, { body: data })
  return result.data
}
//소화물 삭제
const deleteFreight = async (data) => {
  const result = await api.delete(`/freight`, { body: data })
  return result.data
}
//소화물 출력
const patchFreightsPrint = async (data) => {
  const result = await api.patch(`/freights/print`, { body: data })
  return result.data
}

//소화물 엑셀저장
const getFreightDownloadExcel = async (data) => {
  let query = `?`
  if (data.startDate) {
    query += `startDate=${data.startDate}`
  }
  if (data.endDate) {
    query += `&endDate=${data.endDate}`
  }
  if (data.searchType) {
    query += `&searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }
  if (data.isScan !== undefined) {
    query += `&isScan=${data.isScan}`
  }
  const result = await api.get(`/freights/download${query}`, {
    options: { responseType: "blob" },
  })
  return result.data
}

//소화물 추적 엑셀저장
const getFreightTrackDownloadExcel = async (data) => {
  let query = `?`
  if (data.startDate) {
    query += `startDate=${data.startDate}`
  }
  if (data.endDate) {
    query += `&endDate=${data.endDate}`
  }
  if (data.searchType) {
    query += `&searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }
  const result = await api.get(`/freight/tracks/download${query}`, {
    options: { responseType: "blob" },
  })
  return result.data
}

//발신지점,수신지점 => 발신지사,수신지사
const getPointToBranch = async (data) => {
  const result = await api.get(`/point/to/branch?sendPointSeq=${data.sendPointSeq}&recvPointSeq=${data.recvPointSeq}`)
  return result.data
}

//소화물 엑셀 등록
const postFreightsUpload = async (data) => {
  const result = await api.post(`/freights/upload`, {
    body: data,
  })
  return result.data
}
export default {
  getFreightTracks,
  getFreights,
  getFreight,
  postFreight,
  patchFreight,
  deleteFreight,
  patchFreightsPrint,
  getFreightDownloadExcel,
  getFreightTrackDownloadExcel,
  getPointToBranch,
  postFreightsUpload,
}
