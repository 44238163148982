import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const {
  SET_MANAGE_FREIGHT_TRACKS,
  SET_MANAGE_FREIGHT_TRACKS_TOTAL,
  SET_MANAGE_POUCH_TRACKS,
  SET_MANAGE_POUCH_TRACKS_TOTAL,
  SET_MANAGE_FREIGHT_TRACKS_BILL_PRICE,
  SET_MANAGE_FREIGHT_TRACKS_SETTLE_PRICE,
  SET_MANAGE_POUCH_TRACKS_BILL_PRICE,
  SET_MANAGE_POUCH_TRACKS_SETTLE_PRICE,
} = actions.ManageAction

// default state value
const initialState = {
  _manageFreightTracks: [],
  _manageFreightTracksTotal: 0,
  _managePouchTracks: [],
  _managePouchTracksTotal: 0,

  _manageFreightBillPrice: 0,
  _manageFreightSettlePrice: 0,
  _managePouchBillPrice: 0,
  _managePouchSettlePrice: 0,
}

// handle action
export default handleActions(
  {
    [SET_MANAGE_FREIGHT_TRACKS_BILL_PRICE]: (state, action) => ({
      ...state,
      _manageFreightBillPrice: action.payload,
    }),
    [SET_MANAGE_FREIGHT_TRACKS_SETTLE_PRICE]: (state, action) => ({
      ...state,
      _manageFreightSettlePrice: action.payload,
    }),
    [SET_MANAGE_POUCH_TRACKS_BILL_PRICE]: (state, action) => ({
      ...state,
      _managePouchBillPrice: action.payload,
    }),
    [SET_MANAGE_POUCH_TRACKS_SETTLE_PRICE]: (state, action) => ({
      ...state,
      _managePouchSettlePrice: action.payload,
    }),

    [SET_MANAGE_FREIGHT_TRACKS]: (state, action) => ({
      ...state,
      _manageFreightTracks: action.payload,
    }),
    [SET_MANAGE_FREIGHT_TRACKS_TOTAL]: (state, action) => ({
      ...state,
      _manageFreightTracksTotal: action.payload,
    }),
    [SET_MANAGE_POUCH_TRACKS]: (state, action) => ({
      ...state,
      _managePouchTracks: action.payload,
    }),
    [SET_MANAGE_POUCH_TRACKS_TOTAL]: (state, action) => ({
      ...state,
      _managePouchTracksTotal: action.payload,
    }),
  },
  initialState
)
