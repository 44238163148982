import api from "../utils/api"

//공지사항 리스트
const getBoards = async (data) => {
  const result = await api.get(`/boards?page=${data.page}&count=${data.count}`)
  return result.data
}
//공지사항 상세
const getBoard = async (data) => {
  const result = await api.get(`/board?boardSeq=${data.boardSeq}`)
  return result.data
}
//공지사항 등록
const postBoard = async (data) => {
  const result = await api.post(`/board`, {
    body: data,
  })
  return result.data
}
//공지사항 수정
const patchBoard = async (data) => {
  const result = await api.patch(`/board`, {
    body: data,
  })
  return result.data
}
//공지사항 삭제
const deleteBoard = async (data) => {
  const result = await api.delete(`/board`, {
    body: data,
  })
  return result.data
}
export default {
  getBoards,
  getBoard,
  postBoard,
  patchBoard,
  deleteBoard,
}
