import { createAction } from "redux-actions"
import freightApis from "../apis/freightApis"

// action type
export const SET_FREIGHT_TRACKS = "@tramslogitics/admin/SET_FREIGHT_TRACKS"
export const SET_FREIGHT_TRACKS_TOTAL = "@tramslogitics/admin/SET_FREIGHT_TRACKS_TOTAL"
export const SET_FREIGHTS = "@tramslogitics/admin/SET_FREIGHTS"
export const SET_FREIGHTS_TOTAL = "@tramslogitics/admin/SET_FREIGHTS_TOTAL"
export const INIT_FREIGHT = "@tramslogitics/admin/INIT_FREIGHT"
export const SET_FREIGHT = "@tramslogitics/admin/SET_FREIGHT"
export const SET_FREIGHT_PRINT = "@tramslogitics/admin/SET_FREIGHT_PRINT"
export const INIT_FREIGHT_PRINT = "@tramslogitics/admin/INIT_FREIGHT_PRINT"
// action function
export const setFreightTracks = createAction(SET_FREIGHT_TRACKS)
export const setFreightTracksTotal = createAction(SET_FREIGHT_TRACKS_TOTAL)
export const setFreights = createAction(SET_FREIGHTS)
export const setFreightsTotal = createAction(SET_FREIGHTS_TOTAL)
export const initFreight = createAction(INIT_FREIGHT)
export const setFreight = createAction(SET_FREIGHT)
export const setFreightPrint = createAction(SET_FREIGHT_PRINT)
export const initFreightPrint = createAction(INIT_FREIGHT_PRINT)

//소화물 추적 리스트
export const getFreightTracks = (param) => async (dispatch) => {
  const result = await freightApis.getFreightTracks(param)
  if (result.code === 0) {
    dispatch(setFreightTracks(result.data.tracks))
    dispatch(setFreightTracksTotal(result.data.total))
    return true
  } else return false
}
//소화물 리스트
export const getFreights = (param) => async (dispatch) => {
  const result = await freightApis.getFreights(param)

  if (result.code === 0) {
    dispatch(setFreights(result.data.freights))
    dispatch(setFreightsTotal(result.data.total))
    return true
  } else return false
}
//소화물 상세
export const getFreight = (param) => async (dispatch) => {
  const result = await freightApis.getFreight(param)
  if (result.code === 0) {
    dispatch(setFreight(result.data.freight))
    return true
  } else return false
}
//소화물 등록
export const postFreight = (param) => async (dispatch) => {
  const result = await freightApis.postFreight(param)
  if (result.code === 0) {
    return true
  } else return false
}
//소화물 수정
export const patchFreight = (param) => async (dispatch) => {
  const result = await freightApis.patchFreight(param)
  if (result.code === 0) {
    return true
  } else return false
}
//소화물 삭제
export const deleteFreight = (param) => async (dispatch) => {
  const result = await freightApis.deleteFreight(param)
  if (result.code === 0) {
    return true
  } else return false
}
//소화물 출력
export const patchFreightsPrint = (param) => async (dispatch) => {
  const result = await freightApis.patchFreightsPrint(param)
  if (result.code === 0) {
    return true
  } else return false
}

//소화물 엑셀저장
export const getFreightDownloadExcel = (param) => async () => {
  const result = await freightApis.getFreightDownloadExcel(param)
  return result
}

//소화물 엑셀등록
export const postFreightsUpload = (param) => async () => {
  const result = await freightApis.postFreightsUpload(param)
  return result
}
//소화물 추적 엑셀저장
export const getFreightTrackDownloadExcel = (param) => async () => {
  const result = await freightApis.getFreightTrackDownloadExcel(param)
  return result
}

//발신지점,수신지점 => 발신지사,수신지사
export const getPointToBranch = (param) => async () => {
  const result = await freightApis.getPointToBranch(param)
  if (result.code === 0) {
    return result.data
  } else return false
}
