import { createAction } from "redux-actions"
import boardApis from "../apis/boardApis"

// action type
export const SET_BOARDS = "@tramslogitics/admin/SET_BOARDS"
export const SET_BOARDS_TOTAL = "@tramslogitics/admin/SET_BOARDS_TOTAL"
export const INIT_BOARD = "@tramslogitics/admin/INIT_BOARD"
export const SET_BOARD = "@tramslogitics/admin/SET_BOARD"

// action function
export const setBoards = createAction(SET_BOARDS)
export const setBoardsTotal = createAction(SET_BOARDS_TOTAL)
export const setBoard = createAction(SET_BOARD)
export const initBoard = createAction(INIT_BOARD)

//공지사항 리스트
export const getBoards = (param) => async (dispatch) => {
  const result = await boardApis.getBoards(param)
  if (result.code === 0) {
    dispatch(setBoards(result.data.boards))
    dispatch(setBoardsTotal(result.data.total))
    return true
  } else return false
}
//공지사항 상세
export const getBoard = (param) => async (dispatch) => {
  const result = await boardApis.getBoard(param)
  if (result.code === 0) {
    dispatch(setBoard(result.data.board))
    return true
  } else return false
}
//공지사항 등록
export const postBoard = (param) => async (dispatch) => {
  const result = await boardApis.postBoard(param)
  if (result.code === 0) {
    return true
  } else return false
}
//공지사항 수정
export const patchBoard = (param) => async (dispatch) => {
  const result = await boardApis.patchBoard(param)
  if (result.code === 0) {
    return true
  } else return false
}
//공지사항 삭제
export const deleteBoard = (param) => async (dispatch) => {
  const result = await boardApis.deleteBoard(param)
  if (result.code === 0) {
    return true
  } else return false
}
