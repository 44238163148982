import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const { SET_BRANCHS, SET_BRANCH, INIT_BRANCH, SET_BRANCHS_TOTAL, SET_BRANCHS_ALL } = actions.BranchAction

// default state value
const initialState = {
  _branchs: [],
  _branch: {},
  _branchsTotal: 0,
}

// handle action
export default handleActions(
  {
    [SET_BRANCHS]: (state, action) => ({
      ...state,
      _branchs: action.payload,
    }),
    [SET_BRANCHS_TOTAL]: (state, action) => ({
      ...state,
      _branchsTotal: action.payload,
    }),
    [SET_BRANCH]: (state, action) => ({
      ...state,
      _branch: { ...state._branch, ...action.payload },
    }),
    [INIT_BRANCH]: (state, action) => ({
      ...state,
      _branch: {},
    }),
  },
  initialState
)
