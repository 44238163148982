import api from "../utils/api"

//청구/정산 소화물 리스트
const getManageFreightTracks = async (data) => {
  let query = ``
  if (data.page !== undefined) {
    query += `page=${data.page}`
  }
  if (data.count) {
    query += `&count=${data.count}`
  }
  if (data.startDate) {
    query += `&startDate=${data.startDate}`
  }
  if (data.endDate) {
    query += `&endDate=${data.endDate}`
  }
  if (data.searchType) {
    query += `&searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }
  const result = await api.get(`/manage/freight/tracks?${query}`)
  return result.data
}

//청구/정산 소화물 , 엑셀저장
const getFreightDownloadExcel = async (data) => {
  let query = `?`
  if (data.startDate) {
    query += `startDate=${data.startDate}`
  }
  if (data.endDate) {
    query += `&endDate=${data.endDate}`
  }
  if (data.searchType) {
    query += `&searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }
  if (data.type !== undefined) {
    query += `&type=${data.type}`
  }
  const result = await api.get(`/manage/freight/tracks/download${query}`, {
    options: { responseType: "blob" },
  })
  return result.data
}

//청구/정산 행낭 , 엑셀저장
const getPouchDownloadExcel = async (data) => {
  let query = `?`
  if (data.startDate) {
    query += `startDate=${data.startDate}`
  }
  if (data.endDate) {
    query += `&endDate=${data.endDate}`
  }
  if (data.searchType) {
    query += `&searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }
  if (data.type !== undefined) {
    query += `&type=${data.type}`
  }
  const result = await api.get(`/manage/pouch/tracks/download${query}`, {
    options: { responseType: "blob" },
  })
  return result.data
}

//청구/정산 행낭 리스트
const getManagePouchTracks = async (data) => {
  let query = ``
  if (data.page !== undefined) {
    query += `page=${data.page}`
  }
  if (data.count) {
    query += `&count=${data.count}`
  }
  if (data.startDate) {
    query += `&startDate=${data.startDate}`
  }
  if (data.endDate) {
    query += `&endDate=${data.endDate}`
  }
  if (data.searchType) {
    query += `&searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }
  const result = await api.get(`/manage/pouch/tracks?${query}`)
  return result.data
}

//청구/정산 소화물 금액 수정
const patchManageFreightPrice = async (data) => {
  const result = await api.patch(`/manage/freight/price`, {
    body: data,
  })
  return result.data
}
//청구/정산 행낭 금액 수정
const patchManagePouchPrice = async (data) => {
  const result = await api.patch(`/manage/pouch/price`, {
    body: data,
  })
  return result.data
}
export default {
  getManageFreightTracks,
  getManagePouchTracks,
  patchManageFreightPrice,
  patchManagePouchPrice,
  getFreightDownloadExcel,
  getPouchDownloadExcel,
}
