import api from "../utils/api"

//문서 리스트
const getDocuments = async (data) => {
  let query = ``
  if (data.page !== undefined) {
    query += `page=${data.page}`
  }
  if (data.count) {
    query += `&count=${data.count}`
  }
  if (data.startDate) {
    query += `&startDate=${data.startDate}`
  }
  if (data.endDate) {
    query += `&endDate=${data.endDate}`
  }
  if (data.isScan !== undefined) {
    query += `&isScan=${data.isScan}`
  }
  if (data.searchType) {
    query += `&searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }
  const result = await api.get(`/documents?${query}`)
  return result.data
}
//문서 상세
const getDocument = async (data) => {
  const result = await api.get(`/document?docSeq=${data.docSeq}`)
  return result.data
}
//문서 등록
const postDocument = async (data) => {
  const result = await api.post(`/document`, { body: data })
  return result.data
}
//문서 수정
const patchDocument = async (data) => {
  const result = await api.patch(`/document`, { body: data })
  return result.data
}
//문서 삭제
const deleteDocument = async (data) => {
  const result = await api.delete(`/document`, { body: data })
  return result.data
}
//문서 출력
const patchDocumentsPrint = async (data) => {
  const result = await api.patch(`/documents/print`, { body: data })
  return result.data
}

//문서 엑셀저장
const getDocuDownloadExcel = async (data) => {
  let query = `?`
  if (data.startDate) {
    query += `startDate=${data.startDate}`
  }
  if (data.endDate) {
    query += `&endDate=${data.endDate}`
  }
  if (data.searchType) {
    query += `&searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }
  if (data.isScan !== undefined) {
    query += `&isScan=${data.isScan}`
  }
  const result = await api.get(`/documents/download${query}`, { options: { responseType: "blob" } })
  return result.data
}

//문서 엑셀등록
const postDocumentsUpload = async (data) => {
  const result = await api.post(`/documents/upload`, { body: data })
  return result.data
}

export default {
  getDocuments,
  getDocument,
  postDocument,
  patchDocument,
  deleteDocument,
  patchDocumentsPrint,
  getDocuDownloadExcel,
  postDocumentsUpload,
}
