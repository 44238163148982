import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const {
  SET_LARGE_POUCH_TRACKS,
  SET_LARGE_POUCH_TRACKS_TOTAL,
  SET_LARGE_POUCHS,
  SET_LARGE_POUCHS_TOTAL,
  SET_LARGE_POUCH_ITEMS,
  SET_LARGE_POUCH_ITEMS_TOTAL,
  INIT_LARGE_POUCH,
  SET_LARGE_POUCH,
  SET_LARGE_POUCH_PRINT,

} = actions.LargePouchAction

// default state value
const initialState = {
  _largePouchTracks: [],
  _largePouchTracksTotal: 0,
  _largePouchs: [],
  _largePouchsTotal: 0,
  _largePouchItems: [],
  _largePouchItemsTotal: 0,
  _largePouch: {},
  _largePouchPrint: [],
}

// handle action
export default handleActions(
  {
    [SET_LARGE_POUCH_PRINT]: (state, action) => ({
      ...state,
      _largePouchPrint: action.payload,
    }),

    [SET_LARGE_POUCH_TRACKS]: (state, action) => ({
      ...state,
      _largePouchTracks: action.payload,
    }),
    [SET_LARGE_POUCH_TRACKS_TOTAL]: (state, action) => ({
      ...state,
      _largePouchTracksTotal: action.payload,
    }),

    [SET_LARGE_POUCHS]: (state, action) => ({
      ...state,
      _largePouchs: action.payload,
    }),
    [SET_LARGE_POUCHS_TOTAL]: (state, action) => ({
      ...state,
      _largePouchsTotal: action.payload,
    }),
    [SET_LARGE_POUCH_ITEMS]: (state, action) => ({
      ...state,
      _largePouchItems: action.payload,
    }),
    [SET_LARGE_POUCH_ITEMS_TOTAL]: (state, action) => ({
      ...state,
      _largePouchItemsTotal: action.payload,
    }),
    [INIT_LARGE_POUCH]: (state, action) => ({
      ...state,
      _largePouch: {},
    }),
    [SET_LARGE_POUCH]: (state, action) => ({
      ...state,
      _largePouch: action.payload,
    }),
  },
  initialState
)
