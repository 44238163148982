import api from "../utils/api"

//행낭 추적 리스트
const getPouchTracks = async (data) => {
  let quary = `?`
  if (data.page !== undefined) {
    quary += `page=${data.page}`
  }
  if (data.count) {
    quary += `&count=${data.count}`
  }
  if (data.startDate) {
    quary += `&startDate=${data.startDate}`
  }
  if (data.endDate) {
    quary += `&endDate=${data.endDate}`
  }
  if (data.searchType) {
    quary += `&searchType=${data.searchType}`
  }
  if (data.search) {
    quary += `&search=${data.search}`
  }
  const result = await api.get(`/pouch/tracks${quary}`)
  return result.data
}

//행낭 리스트
const getPouchs = async (data) => {
  let quary = `?`
  if (data.page !== undefined) {
    quary += `page=${data.page}`
  }
  if (data.count) {
    quary += `&count=${data.count}`
  }
  if (data.searchType) {
    quary += `&searchType=${data.searchType}`
  }
  if (data.search) {
    quary += `&search=${data.search}`
  }
  const result = await api.get(`/pouchs${quary}`)
  return result.data
}

//행낭 상세
const getPouch = async (data) => {
  const result = await api.get(`/pouch?pouchSeq=${data.pouchSeq}`)
  return result.data
}

//행낭 등록
const postPouch = async (data) => {
  const result = await api.post(`/pouch`, {
    body: data,
  })
  return result.data
}

//행낭 수정
const patchPouch = async (data) => {
  const result = await api.patch(`/pouch`, {
    body: data,
  })
  return result.data
}
//행낭 삭제
const deletePouch = async (data) => {
  const result = await api.delete(`/pouch`, {
    body: data,
  })
  return result.data
}

//행낭추적 엑셀저장
const getTrackDownloadExcel = async (data) => {
  let query = `?`
  if (data.startDate) {
    query += `startDate=${data.startDate}`
  }
  if (data.endDate) {
    query += `&endDate=${data.endDate}`
  }
  if (data.searchType) {
    query += `&searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }

  const result = await api.get(`/pouch/tracks/download${query}`, { options: { responseType: "blob" } })
  return result.data
}

//행낭관리 엑셀저장
const getManageDownloadExcel = async (data) => {
  let query = `?`

  if (data.searchType) {
    query += `searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }

  const result = await api.get(`/pouchs/download${query}`, { options: { responseType: "blob" } })
  return result.data
}

export default {
  getPouchTracks,
  getPouchs,
  getPouch,
  postPouch,
  patchPouch,
  deletePouch,
  getTrackDownloadExcel,
  getManageDownloadExcel,
}
