import { createAction } from "redux-actions"

import branchApis from "../apis/branchApis"

export const SET_BRANCHS = "@tramslogitics/admin/SET_BRANCHS"
export const SET_BRANCHS_TOTAL = "@tramslogitics/admin/SET_BRANCHS_TOTAL"

export const SET_BRANCH = "@tramslogitics/admin/SET_BRANCH"
export const INIT_BRANCH = "@tramslogitics/admin/INIT_BRANCH"

export const setBranchs = createAction(SET_BRANCHS)
export const setBranchsTotal = createAction(SET_BRANCHS_TOTAL)

export const setBranch = createAction(SET_BRANCH)
export const initBranch = createAction(INIT_BRANCH)

//지사 리스트
export const getBranchList = (param) => async (dispatch) => {
  const result = await branchApis.getBranchs(param)
  
  // console.log("api", result)

  if (result.code === 0) {
    
    // let data = result.data.branchs 
    let data = [{ branchSeq : 230, branchName : 'CJ' }].concat(result.data.branchs) 

    // data.push({ branchSeq : 230, branchName : 'CJ' })

    dispatch(setBranchs(data))

    // dispatch(setBranchsTotal(result.data.total))
    return true
  } else {
    return false
  }
}

export const getBranchs = (param) => async (dispatch) => {
  const result = await branchApis.getBranchs(param)
  
  // console.log("api", result)

  if (result.code === 0) {
    dispatch(setBranchs(result.data.branchs))
    dispatch(setBranchsTotal(result.data.total))
    return true
  } else {
    return false
  }
}

//지사 상세
export const getBranch = (param) => async (dispatch) => {
  const result = await branchApis.getBranch(param)
  if (result.code === 0) {
    dispatch(setBranch(result.data.branch))
    return true
  } else {
    return false
  }
}

//지사 등록
export const postBranch = (param) => async (dispatch) => {
  const result = await branchApis.postBranch(param)
  if (result.code === 0) {
    return true
  } else {
    return false
  }
}

//지사 수정
export const patchBranch = (param) => async (dispatch) => {
  const result = await branchApis.patchBranch(param)
  if (result.code === 0) {
    return true
  } else {
    return false
  }
}

//지사 삭제
export const deleteBranch = (param) => async (dispatch) => {
  const result = await branchApis.deleteBranch(param)
  if (result.code === 0) {
    return true
  } else {
    return false
  }
}

//지사 아이디 중복검사
export const getBranchCheckId = (param) => async (dispatch) => {
  const result = await branchApis.getBranchCheckId(param)
  if (result.code === 0) {
    return result.data.status
  } else {
    return false
  }
}

//지사 엑셀저장
export const getDownloadExcel = (param) => async () => {
  const result = await branchApis.getDownloadExcel(param)
  return result
}
