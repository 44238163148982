import { createAction } from "redux-actions"
import documentApis from "../apis/documentApis"

// action type
export const SET_DOCUMENTS = "@tramslogitics/admin/SET_DOCUMENTS"
export const SET_DOCUMENTS_TOTAL = "@tramslogitics/admin/SET_DOCUMENTS_TOTAL"
export const INIT_DOCUMENT = "@tramslogitics/admin/INIT_DOCUMENT"
export const SET_DOCUMENT = "@tramslogitics/admin/SET_DOCUMENT"
export const SET_DOCUMENT_PRINT = "@tramslogitics/admin/SET_DOCUMENT_PRINT"
export const INIT_DOCUMENT_PRINT = "@tramslogitics/admin/INIT_DOCUMENT_PRINT"

// action function
export const setDocuments = createAction(SET_DOCUMENTS)
export const setDocumentsTotal = createAction(SET_DOCUMENTS_TOTAL)
export const initDocument = createAction(INIT_DOCUMENT)
export const setDocument = createAction(SET_DOCUMENT)

export const setDocumentPrint = createAction(SET_DOCUMENT_PRINT)
export const initDocumentPrint = createAction(INIT_DOCUMENT_PRINT)
//문서 리스트
export const getDocuments = (param) => async (dispatch) => {
  const result = await documentApis.getDocuments(param)
  if (result.code === 0) {
    dispatch(setDocuments(result.data.documents))
    dispatch(setDocumentsTotal(result.data.total))
    return true
  } else return false
}
//문서 상세
export const getDocument = (param) => async (dispatch) => {
  const result = await documentApis.getDocument(param)
  if (result.code === 0) {
    dispatch(setDocument(result.data.document))
    return true
  } else return false
}
//문서 등록
export const postDocument = (param) => async (dispatch) => {
  const result = await documentApis.postDocument(param)
  if (result.code === 0) {
    return true
  } else return false
}
//문서 수정
export const patchDocument = (param) => async (dispatch) => {
  const result = await documentApis.patchDocument(param)
  if (result.code === 0) {
    return true
  } else return false
}

//문서 삭제
export const deleteDocument = (param) => async (dispatch) => {
  const result = await documentApis.deleteDocument(param)
  if (result.code === 0) {
    return true
  } else return false
}

//문서 출력
export const patchDocumentsPrint = (param) => async (dispatch) => {
  const result = await documentApis.patchDocumentsPrint(param)
  if (result.code === 0) {
    return true
  } else return false
}

//문서 엑셀저장
export const getDocuDownloadExcel = (param) => async () => {
  const result = await documentApis.getDocuDownloadExcel(param)
  return result
}

//문서 엑셀등록
export const postDocumentsUpload = (param) => async () => {
  const result = await documentApis.postDocumentsUpload(param)
  return result
}
