import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const { SET_EMPLOYEES, SET_EMPLOYEE, INIT_EMPLOYEE, SET_EMPLOYEES_TOTAL } = actions.EmployeeAction

// default state value
const initialState = {
  _employees: [],
  _employee: {},
  _employeesTotal: 0,
}

// handle action
export default handleActions(
  {
    [SET_EMPLOYEES]: (state, action) => ({
      ...state,
      _employees: action.payload,
    }),
    [SET_EMPLOYEES_TOTAL]: (state, action) => ({
      ...state,
      _employeesTotal: action.payload,
    }),
    [SET_EMPLOYEE]: (state, action) => ({
      ...state,
      _employee: action.payload,
    }),
    [INIT_EMPLOYEE]: (state, action) => ({
      ...state,
      _employee: {},
    }),
  },
  initialState
)
