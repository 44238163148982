import { createAction } from "redux-actions"
import pointApis from "../apis/pointApis"

// action type
export const SET_POINTS = "@tramslogitics/admin/SET_POINTS"
export const SET_POINT = "@tramslogitics/admin/SET_POINT"
export const INIT_POINT = "@tramslogitics/admin/INIT_POINT"
export const SET_POINTS_TOTAL = "@tramslogitics/admin/SET_POINTS_TOTAL"

// action function
export const setPoints = createAction(SET_POINTS)
export const setPoint = createAction(SET_POINT)
export const initPoint = createAction(INIT_POINT)
export const setPointsTotal = createAction(SET_POINTS_TOTAL)

//지점 아이디 중복검사
export const getPointCheckId = (param) => async (dispatch) => {
  const result = await pointApis.getPointCheckId(param)
  if (result.code === 0) {
    return result.data.status === 1
  } else return false
}

//지점 리스트
export const getPoints = (param) => async (dispatch) => {
  const result = await pointApis.getPoints(param)
  if (result.code === 0) {
    dispatch(setPoints(result.data.points))
    dispatch(setPointsTotal(result.data.total))
    return true
  } else return false
}

//지점 상세
export const getPoint = (param) => async (dispatch) => {
  const result = await pointApis.getPoint(param)
  if (result.code === 0) {
    dispatch(setPoint(result.data.point))
    return true
  } else return false
}

//지점 등록
export const postPoint = (param) => async (dispatch) => {
  const result = await pointApis.postPoint(param)
  if (result.code === 0) {
    return true
  } else return false
}
//지점 수정
export const patchPoint = (param) => async (dispatch) => {
  const result = await pointApis.patchPoint(param)
  if (result.code === 0) {
    return true
  } else return false
}

//지점 삭제
export const deletePoint = (param) => async (dispatch) => {
  const result = await pointApis.deletePoint(param)
  if (result.code === 0) {
    return true
  } else return false
}

//지점 엑셀저장
export const getDownloadExcel = (param) => async () => {
  const result = await pointApis.getDownloadExcel(param)
  return result
}

//지점 엑셀등록
export const postPointsUpload = (param) => async () => {
  const result = await pointApis.postPointsUpload(param)
  return result
}
