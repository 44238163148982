import { combineReducers } from "redux"
import { penderReducer } from "redux-pender"

import ConfigReducer from "./config"
import DriverReducer from "./driver"
import BranchReducer from "./branch"
import EmployeeReducer from "./employee"
import CustomerReducer from "./customer"
import PointReducer from "./point"
import BoardReducer from "./board"
import PouchReducer from "./pouch"
import LargePouchReducer from "./largePouch"
import FreightReducer from "./freight"
import DocumentReducer from "./document"
import ManageReducer from "./manage"

export default combineReducers({
  pender: penderReducer,
  ConfigReducer,
  DriverReducer,
  BranchReducer,
  EmployeeReducer,
  CustomerReducer,
  PointReducer,
  BoardReducer,
  PouchReducer,
  LargePouchReducer,
  FreightReducer,
  DocumentReducer,
  ManageReducer,
})
