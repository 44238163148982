import { createAction } from "redux-actions"
import driverApis from "../apis/driverApis"

// action type
//본사
export const SET_DRIVERS = "@tramslogitics/admin/SET_DRIVERS"
export const SET_DRIVERS_TOTAL = "@tramslogitics/admin/SET_DRIVERS_TOTAL"

//지사
export const SET_DRIVERS_LOCAL = "@tramslogitics/admin/SET_DRIVERS_LOCAL"
export const SET_DRIVERS_LOCAL_TOTAL = "@tramslogitics/admin/SET_DRIVERS_LOCAL_TOTAL"

//본사 상세
export const SET_DRIVER = "@tramslogitics/admin/SET_DRIVER"
export const INIT_DRIVER = "@tramslogitics/admin/INIT_DRIVER"

//지사 상세
export const SET_DRIVER_LOCAL = "@tramslogitics/admin/SET_DRIVER_LOCAL"
export const INIT_DRIVER_LOCAL = "@tramslogitics/admin/INIT_DRIVER_LOCAL"

export const SET_DRIVER_POUCHS = "@tramslogitics/admin/SET_DRIVER_POUCHS"
export const INIT_DRIVER_POUCHS = "@tramslogitics/admin/INIT_DRIVER_POUCHS"
// action function
//본사
export const setDrivers = createAction(SET_DRIVERS)
export const setDriversTotal = createAction(SET_DRIVERS_TOTAL)
//지사
export const setDriversLocal = createAction(SET_DRIVERS_LOCAL)
export const setDriversLocalTotal = createAction(SET_DRIVERS_LOCAL_TOTAL)
//본사 상세
export const setDriver = createAction(SET_DRIVER)
export const initDriver = createAction(INIT_DRIVER)
//지사 상세
export const setDriverLocal = createAction(SET_DRIVER_LOCAL)
export const initDriverLocal = createAction(INIT_DRIVER_LOCAL)

export const setDriverPouchs = createAction(SET_DRIVER_POUCHS)
export const initDriverPouchs = createAction(INIT_DRIVER_POUCHS)

//본사 기사 리스트
export const getDrivers = (param) => async (dispatch) => {
  const result = await driverApis.getDrivers(param)
  if (result.code === 0) {
    dispatch(setDrivers(result.data.drivers))
    dispatch(setDriversTotal(result.data.total))
    return true
  } else return false
}

//지사 기사 리스트
export const getDriversLocal = (param) => async (dispatch) => {
  const result = await driverApis.getDrivers(param)
  if (result.code === 0) {
    dispatch(setDriversLocal(result.data.drivers))
    dispatch(setDriversLocalTotal(result.data.total))
    return true
  } else return false
}

//본사 기사 상세
export const getDriver = (param) => async (dispatch) => {
  const result = await driverApis.getDriver(param)
  if (result.code === 0) {
    dispatch(setDriver(result.data.driver))
    return true
  } else return false
}

//지사 기사 상세
export const getDriverLocal = (param) => async (dispatch) => {
  const result = await driverApis.getDriver(param)
  if (result.code === 0) {
    dispatch(setDriverLocal(result.data.driver))
    return true
  } else return false
}
//기사등록
export const postDriver = (param) => async (dispatch) => {
  const result = await driverApis.postDriver(param)
  if (result.code === 0) {
    return true
  } else return false
}

//기사 수정
export const patchDriver = (param) => async (dispatch) => {
  const result = await driverApis.patchDriver(param)
  if (result.code === 0) {
    return true
  } else return false
}

//기사 삭제
export const deleteDriver = (param) => async (dispatch) => {
  const result = await driverApis.deleteDriver(param)
  if (result.code === 0) {
    return true
  } else return false
}

//기사 아이디 중복검사
export const getDriverCheckId = (param) => async (dispatch) => {
  const result = await driverApis.getDriverCheckId(param)
  if (result.code === 0) {
    return result.data.status
  } else return false
}

//기사 엑셀저장
export const getDownloadExcel = (param) => async () => {
  const result = await driverApis.getDownloadExcel(param)
  return result
}

//기사 행낭 리스트
export const getDriverPouchs = (param) => async (dispatch) => {
  const result = await driverApis.getDriverPouchs(param)
  if (result.code === 0) {
    dispatch(setDriverPouchs(result.data.pouchs))
    return true
  } else return false
}
